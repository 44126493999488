import { get } from "@data/index";
import {
  BillingCustomerWithSubscriptionsResponseData,
  CountCustomersResponse,
  ListCustomersWithSubscriptionsRequest,
} from "@models/api";

export const listCustomers = async (
  flt?: ListCustomersWithSubscriptionsRequest,
): Promise<BillingCustomerWithSubscriptionsResponseData[]> => {
  return get<
    BillingCustomerWithSubscriptionsResponseData[],
    ListCustomersWithSubscriptionsRequest
  >(`billing/customers`, flt);
};

export const countCustomers = async (
  flt?: ListCustomersWithSubscriptionsRequest,
): Promise<CountCustomersResponse> => {
  return get<CountCustomersResponse, ListCustomersWithSubscriptionsRequest>(
    `billing/customers/count`,
    flt,
  );
};
