import { FeatureType } from "@models/feature";
import { FeatureUsage } from "@models/usage";
import { MetricPeriodDisplayShortHand } from "@modules/plans/consts";
import { Icon } from "@ui/Icon";
import { formatUTCDate } from "@utils/date";
import { capitalize, formatCurrency } from "@utils/strings";
import cx from "classnames";
import pluralize from "pluralize";

export interface CompanyEntitlementCellProps {
  usage: FeatureUsage;
}

const EntitlementCellBoolean = ({ access }: { access: boolean }) => {
  const iconStyles = cx("text-3xl", access ? "text-green-300" : "text-red-500");
  return (
    <div className="flex flex-row space-x-2 items-center">
      <Icon className={iconStyles} name={access ? "boolean-on" : "boolean"} />
      {access ? "On" : "Off"}
    </div>
  );
};

const EntitlementCellNumeric = ({ usage }: CompanyEntitlementCellProps) => {
  if (!usage.feature) return;

  const numericLimit =
    typeof usage.allocation === "undefined" ? "∞" : usage.allocation.toString();
  let smallText =
    typeof usage.allocation === "undefined" ? "No Limit" : "Numerical limit";
  if (usage.priceBehavior === "overage") {
    const price =
      usage.period === "current_month"
        ? usage.monthlyUsageBasedPrice
        : usage.yearlyUsageBasedPrice;
    if (!price) {
      return;
    }

    if (!price) {
      return;
    }

    const { priceTier } = price;
    if (priceTier.length) {
      smallText = `${formatCurrency(
        priceTier[priceTier.length - 1].perUnitPrice ??
          Number(priceTier[priceTier.length - 1].perUnitPriceDecimal),
        price?.currency,
      )}/${pluralize(
        capitalize(usage.feature.name),
        1,
      )}/${price?.interval} overage`;
    } else {
      smallText = `${formatCurrency(price?.price ?? 0, price?.currency, price?.priceDecimal)}/${pluralize(
        capitalize(usage.feature.name),
        1,
      )}/${price?.interval} overage`;
    }
  }

  if (usage.priceBehavior && usage.priceBehavior !== "overage") {
    const price =
      usage.period === "current_month"
        ? usage.monthlyUsageBasedPrice
        : usage.yearlyUsageBasedPrice;
    smallText = `${formatCurrency(price?.price ?? 0, price?.currency)}/${pluralize(
      capitalize(usage.feature.name),
      1,
    )}/${price?.interval} per unit`;
  }

  if (typeof usage.allocation !== "undefined" && usage.metricResetAt) {
    smallText = `Resets ${formatUTCDate(usage.metricResetAt)}, ${smallText}`;
  }

  let bigText = `${numericLimit} ${pluralize(
    capitalize(usage.feature.name),
    usage.allocation ?? 0,
  )}`;
  if (usage.period && MetricPeriodDisplayShortHand[usage.period]) {
    bigText += MetricPeriodDisplayShortHand[usage.period];
  }

  if (usage.priceBehavior === "overage" && usage.softLimit) {
    bigText = `${usage.softLimit} ${pluralize(capitalize(usage.feature.name), usage.softLimit)} included`;
  }

  return (
    <div className="flex flex-col space-y-1">
      <div className="leading-none font-medium">{bigText}</div>
      <div className="leading-none text-sm text-gray-400">{smallText}</div>
    </div>
  );
};

export const CompanyEntitlementCell = ({
  usage,
}: CompanyEntitlementCellProps) => {
  if (!usage.feature) return;

  const booleanBased = usage.feature.featureType === FeatureType.Boolean;

  return (
    <div className="flex flex-row items-center">
      {booleanBased ? (
        <EntitlementCellBoolean access={usage.access} />
      ) : (
        <EntitlementCellNumeric usage={usage} />
      )}
    </div>
  );
};
